/*
 * @Description:
 * @Author: pangyue
 * @Date: 2021-11-19 21:15:45
 */

/**
 * 获取链接上的参数
 */
 export const getUrlQuery = () => {
  var href = window.location.href;
  var index = href.indexOf('?')
  var query = href.substr(index)
  var theRequest = new Object();
  if (query.indexOf("?") != -1) {
    var str = query.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

/**
 * 版本对比
 * oldVersion < newVersion: return -1
 * oldVersion = newVersion: return 0
 * oldVersion > newVersion: return 1
 */
 export function compareVersion(oldV, newV) {
  if (typeof (oldV) !== 'string' || typeof (newV) !== 'string') {
    console.warn('版本号格式不对')
    return;
  }
  if (oldV === newV) {
    return 0;
  }
  const vs1 = oldV.split(".").map(a => parseInt(a));
  const vs2 = newV.split(".").map(a => parseInt(a));
  const length = Math.min(vs1.length, vs2.length);
  for (let i = 0; i < length; i++) {
    if (vs1[i] > vs2[i]) {
      return 1;
    } else if (vs1[i] < vs2[i]) {
      return -1;
    }
  }
  if (length === vs1.length) {
    return -1;
  } else {
    return 1;
  }
}

export function copy(data) {
    const url = data;
    const oInput = document.createElement("textarea");
    oInput.value = url;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象;
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.remove();
}