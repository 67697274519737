<template>
    <div class="verificationCode">
        <div class="main-notification">
            <h1>青虎云号短信</h1>
            <div class="content">
                <section>
                    <span class="content-title">云号码：</span><div>{{ verificationSmsInfo.phone }}</div>
                </section>
                <section>
                    <span class="content-title">短信内容：</span><div>{{ verificationSmsInfo.msgBody }}</div>
                </section>
                <section>
                    <span class="content-title">接收时间：</span><div>{{ verificationSmsInfo.createTime }}</div>
                </section>
            </div>
            <div class="bottom" v-show="isHasCode">
                <button size="small" type="primary" class="btn" @click="copyVerificationCode">{{ copyText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { getUrlQuery, compareVersion, copy } from '@/assets/js/utils'
import { extensionId } from '@/assets/js/config'

export default {
    name: 'Notification',
    data () {
        return {
            loading: false,
            msg: {},
            extensionId: extensionId,
            verificationSmsInfo: {},
            copyText: '复制验证码',
            copyTimer: null,
            isHasCode: false,
        }
    },
    mounted () {
        this.hideSidebar()
        this.updateExtensionId()
        this.getMessageSms()
    },
    methods: {
        updateExtensionId () {
            if (location.host === 'p1.tiger-test.mobduos.com') {
                this.extensionId = 'emidohgdckobbjdcfbjmamalgcdkmlpc'
            }
            window.postMessage({ code: "get-verification-sms" }, "*");
            window.addEventListener('message', res => {
                const code = res.data.code
                const data = res.data.data
                if (code === 'return-get-verification-sms') {
                    // 3.0.57 版本以下的小虎内核，插件id不一样
                    if (compareVersion(data.version, '3.0.57') < 0) {
                        this.extensionId = 'ldpneacpcjphjdffanpldnecidakdgbc'
                    }
                }
            })
        },
        hideSidebar () {
            let count = 0
            const timer = setInterval(() => {
                window.postMessage({ code: "hide-sidebar" }, "*");
                ++count
                if (count > 20) {
                    clearInterval(timer)
                }
            }, 200)
        },
        /**
         * 获取短信内容
         */
        getMessageSms () {
            window.chrome.runtime.sendMessage(
                this.extensionId,
                {
                    code: "get-verification-sms",
                },
                (res) => {
                    console.log('get-verification-sms', res);
                    if(res.code == "return-get-verification-sms"){
                        this.verificationSmsInfo = res.data;
                        this.isHasCode = (this.verificationSmsInfo.msgBody || '').includes('验证码');

                        if(!this.verificationSmsInfo.msgBody) {
                            window.close();
                        };
                        console.log('res', res);
                    }
                }
            );
        },
        copyVerificationCode() {
            if(this.copyText == '已成功复制') return;
            const smsText = this.verificationSmsInfo.msgBody || '';
            const regex = /\d{4,6}/;
            const match = smsText.match(regex);
            if (match) {
                const verificationCode = match[0];
                console.log("验证码：", verificationCode);
                copy(verificationCode);
                this.copySuccess();
            } else {
                console.log("未找到验证码");
            }
        },
        copySuccess() {
            if(this.copyTimer) {
                clearInterval(this.copyTimer);
                this.copyTimer = null;
            };
            this.copyText = '已成功复制';
            this.copyTimer = setTimeout(() => {
                this.copyText = '复制验证码';
            }, 2000);

        }
    },
    destroyed(){
        window.postMessage({ code:'show-sidebar' }, "*");
    },
}
</script>

<style scoped>
.verificationCode{
    height: calc(100vh - 48px);
    padding: 20px 24px;
    /*background: linear-gradient(180deg, rgba(8,193,138,0.2) 0%, rgba(8,193,138,0) 100%);*/
    position: relative;
    z-index: 999999999999999999999999;
}
.main-notification{
    height: 100%;
    /*background-image: url('../assets/pop-win-bg.png');*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 200px;
}
h1{
    font-weight: bold;
    color: #1D2129;
    line-height: 16px;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.content{
    margin-top: 24px;
    max-width: 400px;
    text-align: justify;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    min-height: 88px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.content section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.content section + section {
    margin-top: 16px;
}
.content .content-title {
    width: 72px;
    display: inline-block;
    text-align: justify;
    text-align-last: justify;
    color: #86909C;
    flex-shrink: 0;

}
.bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #999999;
    margin: 24px 0 0 0;
}
.bottom button{
    background-color: #08C18A;
    border-color: #08C18A;
    outline: 0;
    padding: 0 7px;
    border-radius: 4px;
    line-height: 32px;
    width: 156px;
    height: 32px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    border: 0;
    font-size: 12px;
    color: #ffffff;
}
</style>
