/*
 * @Description:
 * @Author: pangyue
 * @Date: 2021-11-19 20:37:25
 */
import axios from 'axios'

// 创建axios实例
const instance = axios.create({
  timeout: 30000, // request timeout*/
  baseURL:'/',// 自定义反向代理
})

instance.interceptors.request.use(
  async config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


instance.interceptors.response.use(
  async response => {
    return response.data;
  },
  async error => {
    if(error && error.response){
          const {status, data} = error.response
          console.log(status)
          if(error.message.includes('timeout')){   // 响应超时
              return {success:false,message:'网络超时'}
          }
          else if([401,403].includes(status)){ // 401 403 未授权
              return {success:false,status:401,message:`接口401，Token失效`}
          }
          else if([500,502,503,504].includes(status)){
              return {success:false,message:data&&data.message||`服务器异常:${status}`}
          }
          else if(status==404){
              return {success:false,message:`接口404，请联系客服`}
          }
          else if(status==429){
              return {success:false,status:429,message:`接口429，网关报错`}
          }
          else return {success:false,message:data&&data.message||`接口异常：${error}`}
    }
    else{
      return {success:false,message:`网络异常`}
    }
  }
)


//1、get请求处理
const toget=async (url,param,headers)=>{
  return instance.get(url, {
    params: param,
    headers
  })
}


// 2、post请求处理
const topost=async (url,param,headers)=>{
  return instance.post(url, param, {
    headers
  })
}


// 定义对外Get、Post、File请求
export default {
  get(url, param = {}, headers = {}) {
    return toget(url,param,headers)
  },
  post(url, param = null, headers = {}) {
    return topost(url,param,Object.assign({"Content-Type": "application/json"},headers))
  },
};