<template>
    <div class="notification" @click="clickHandle">
        <div class="main-notification">
            <h1>{{ msg.title }}</h1>
            <div class="content">
                {{ msg.sysSendMessage }}
            </div>
            <div class="bottom">
            <span class="time">
              {{ msg.updateTime }}
            </span>
                <button size="small" type="primary" class="btn">查看更多</button>
            </div>
        </div>
    </div>
</template>

<script>
import { getMsgSysInfo } from "@/api/notification"
import { getUrlQuery, compareVersion } from '@/assets/js/utils'
import { extensionId } from '@/assets/js/config'

export default {
    name: 'Notification',
    data () {
        return {
            loading: false,
            msg: {},
            extensionId: extensionId,
        }
    },
    mounted () {
        this.hideSidebar()
        this.getMessage()
        this.updateExtensionId()
    },
    methods: {
        updateExtensionId () {
            if (location.host === 'p1.tiger-test.mobduos.com') {
                this.extensionId = 'emidohgdckobbjdcfbjmamalgcdkmlpc'
            }
            window.postMessage({ code: "get_browser_info" }, "*");
            window.addEventListener('message', res => {
                const code = res.data.code
                const data = res.data.data
                if (code === 'return_browser_info') {
                    // 3.0.57 版本以下的小虎内核，插件id不一样
                    if (compareVersion(data.version, '3.0.57') < 0) {
                        this.extensionId = 'ldpneacpcjphjdffanpldnecidakdgbc'
                    }
                }
            })
        },
        hideSidebar () {
            let count = 0
            const timer = setInterval(() => {
                window.postMessage({ code: "hide-sidebar" }, "*");
                ++count
                if (count > 20) {
                    clearInterval(timer)
                }
            }, 200)
        },
        getMessage () {
            const query = getUrlQuery()
            if (query.id) {
                let params = {
                    msgType: 2,
                    id: query.id,
                    pageNo: 1,
                    pageSize: 1,
                    deviceId: query.deviceId,
                }
                getMsgSysInfo(params).then(res => {
                    if (res.data && res.data[0]) {
                        this.msg = res.data[0]
                        this.recordShow()
                    }
                })
            }
        },
        /**
         * 点击事件
         */
        clickHandle () {
            this.recordClick(() => {
                const url = this.msg.urlLink
                window.open(url, '_blank')
                window.close()
            })
        },
        /**
         * 埋点
         */
        recordShow () {
            const data = {
                type: 'system',
                title: this.msg.title,
                content: this.msg.sysSendMessage,
            }
            window.chrome.runtime.sendMessage(
                this.extensionId,
                {
                    code: "push-notification",
                    data,
                },
                (res) => {}
            );
        },
        /**
         * 埋点
         */
        recordClick (callback) {
            const data = {
                type: 'system',
                title: this.msg.title,
                content: this.msg.sysSendMessage,
            }
            window.chrome.runtime.sendMessage(
                this.extensionId,
                {
                    code: "read-notification",
                    data,
                },
                (res) => {
                    if(res.code=='return-notification-click'){
                        callback && callback()
                    }
                }
            );
        },
    },
    destroyed(){
        window.postMessage({ code:'show-sidebar' }, "*");
    },
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}
.notification{
    height: calc(100vh - 48px);
    padding: 20px 24px;
    background: linear-gradient(180deg, rgba(8,193,138,0.2) 0%, rgba(8,193,138,0) 100%);
    cursor: pointer;
    position: relative;
    z-index: 999999999999999999999999;
}
.main-notification{
    height: 100%;
    background-image: url('../assets/pop-win-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 200px;
}
h1{
    text-align: center;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    font-size: 18px;
    margin: 0 0 10px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.content{
    text-align: justify;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    height: 88px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #999999;
    margin: 10px 0 0 0;
}
.bottom button{
    color: #000;
    background-color: #08C18A;
    border-color: #08C18A;
    outline: 0;
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    border: 0;
    font-size: 12px;
    color: #ffffff;
}
</style>
