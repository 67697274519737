/*
 * @Description: 通用配置
 * @Author: pangyue
 * @Date: 2021-12-27 18:15:06
 */


// export const extensionId = "ldpneacpcjphjdffanpldnecidakdgbc"; // 本地环境用
// export const extensionId = "emidohgdckobbjdcfbjmamalgcdkmlpc"; // 测试环境用
export const extensionId = "ojhcnnhfmpbnpdpigfaglnnoncnnnoec"; // 生产环境用
