import Vue from 'vue'
import VueRouter from 'vue-router'
import SysNotification from '../views/SysNotification.vue';
import VerificationCodeSMS from '../views/VerificationCodeSMS.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SysNotification',
    component: SysNotification
  },
  {
    path: '/code',
    name: 'VerificationCodeSMS',
    component: VerificationCodeSMS
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
